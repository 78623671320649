import { Box, Button, Flex, Input, Table, Tag, Tbody, Th, Tr, Td, Thead, Center, Text, Tooltip, Select, Spinner, Link, 
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAllTicketAction, updateTicketAction, getCurrentTicketUser, getSingleTicketAction } from '../../redux/reducers/tickets'
import { map, range, max } from 'lodash'
import moment from 'moment'
import { STATUS } from '../../App/Constants'
import Pagination from "@choc-ui/paginator";
import { ArrowBackIcon, CalendarIcon, ChevronLeftIcon, ChevronRightIcon, EditIcon, RepeatClockIcon, TimeIcon } from '@chakra-ui/icons'
import { useHistory, useLocation } from 'react-router-dom'
import { mobileView, webView } from "../../utils/Helper";
import { DiscussionComments } from '../../routes/DiscussionCommunity/DiscussionComments'

let statusList = [{value:'Pending', color:'blue'}, {value:'Open', color:'green'}, {value:'Closed', color:'red'}, {value:'Duplicate', color:'yellow'}]
    
export default function Desk(props) {
    
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [status, setStatus ] = useState('');
    const [ currentUser, setCurrentUser ] = useState({})
    const [ currentTicket, setCurrentTicket ] = useState(null);
    const [ showFilterModal, setShowFilterModal] = useState(false); 
    const { allTicket, getTicketStatus, updateTicketStatus, currentStudent } = useSelector( s => ({ allTicket : s.ticket.allTicket, getTicketStatus: s.ticket.getTicketStatus, updateTicketStatus: s.ticket.updateTicketStatus, currentStudent: s.ticket.currentStudent }))
    const { docs, total, page, pages, limit } = allTicket;
    const history = useHistory();
    const location = useLocation();
    const search = location.search;
    const params = new URLSearchParams(search);
    const user = params.get('user'); 
    const dispatch = useDispatch()

    useEffect(() => {
        if(user)
            dispatch(getCurrentTicketUser({ userId: user }))
    },[user, dispatch])

    useEffect( () => {
        if(currentUser._id || !user)
            getTickets({});
    },[currentUser, getTickets])

    useEffect( () => {
        if(currentStudent)
            setCurrentUser(currentStudent.user)
    },[currentStudent])

    const handleCloseTicket = (data) => {
        dispatch(updateTicketAction(data));
    }

    const getTickets = (data = {}) => {
        if(startDate)
            data['startDate'] = moment(startDate).format('YYYY-MM-DD');
        if(endDate)
            data['endDate'] = moment(endDate).format('YYYY-MM-DD');
        if(status)
            data['status'] = status;
        if(currentUser._id)
            data['userId'] = currentUser._id
        dispatch(getAllTicketAction(data));
    }

    const resetState = () => {
        setStartDate('');
        setEndDate('');
        setStatus('');
    }

    const resetHandler = () => {
        resetState()
        dispatch(getAllTicketAction({}));
    }

    const handlePageChange  = (page) => {
        getTickets({ page: page })
    }  

    const onUserNameClick = (user) => {
        resetState();
        let paramData = {};
        if(user._id) paramData = {user: user._id}
        else setCurrentUser({});
        const params = new URLSearchParams(paramData);
        history.replace({ search: params.toString() });    
    }

    const showTicket = (t) => {
        setCurrentTicket(t)
    }

    return (
        <Box w="100%">
            {
                getTicketStatus === STATUS.FETCHING && !currentTicket ?
                   <Center><Spinner size='md'/></Center> 
                :
                <Box>
                    { currentUser._id && <Flex mb={4} alignItems='center' pos='sticky' top='0px' py={5} px={5} bg="white" zIndex='99' color="#4559d2" fontSize={[ 12, 14, 16, 18 ]}><ArrowBackIcon onClick={ () => onUserNameClick({}) } cursor='pointer' mr={2}/><Text m="0px" fontWeight='bold'>{currentUser.name}</Text></Flex> }
                    <Box pb={8} pt={ currentUser._id ? "0px" : 4 } px={[2, 4, 6, 8 ]} ml={2}>
                        <Box {...webView}>                       
                            <WebFilterOptions startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} status={status} setStatus={setStatus} getTickets={getTickets} resetHandler={resetHandler}/>
                        </Box>
                        { showFilterModal && <FilterModal onClose={ () => setShowFilterModal(false) } startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} status={status} setStatus={setStatus} getTickets={ () => { setShowFilterModal(false); getTickets({})}} resetHandler={ () => {setShowFilterModal(false); resetHandler()}}/> }
                        
                        <Flex mt='1rem' alignItems='end' justifyContent='space-between' alignItems='center' flexDir={['column', 'column', 'column', 'row']}>
                            <Flex w={["100%","100%", "100%", '70%']} justifyContent='space-between'>
                                <Box fontSize={[ 15, 15, 17, 20 ]} fontWeight='bold' mr={3} mt={2}>Total Tickets: { total || 0}</Box>
                                <Box {...mobileView}>
                                    <MobileFilterOptions setShowFilterModal={setShowFilterModal}/>
                                </Box>
                            </Flex>
                            <Box my={3}>
                                <Box css={{ "& button": { fontSize : '.8rem', padding: 2 } }}>
                                    <Pagination
                                        current={page}
                                        total={total}
                                        pageSize={limit}
                                        onChange={handlePageChange}
                                        paginationProps={{
                                            display: "flex",
                                            justifyContent: "center",
                                            width: "100%"
                                        }}
                                        colorScheme="blue"
                                    />
                                </Box>
                            </Box>
                        </Flex>
                        <Box {...webView}>
                            <TicketTableFormat showTicket={showTicket} isUpdating = { updateTicketStatus === STATUS.FETCHING } allTicket={docs} handleCloseTicket={handleCloseTicket} currentUser = {currentUser._id} onUserNameClick= { onUserNameClick } />
                        </Box>
                        <Box {...mobileView}>
                            <TicketMobileFormat showTicket={showTicket} isUpdating = { updateTicketStatus === STATUS.FETCHING } allTicket={docs} handleCloseTicket={handleCloseTicket} currentUser = {currentUser._id} onUserNameClick= { onUserNameClick } />
                        </Box>
                        <Flex justifyContent={['center','center', 'center','flex-end']} mt={4}>
                            <Box css={{ "& button": { fontSize : '.8rem', padding: 2 } }}>
                                <Pagination
                                        current={page}
                                        total={total}
                                        pageSize={limit}
                                        onChange={handlePageChange}
                                        paginationProps={{
                                        display: "flex",
                                        justifyContent: "center",
                                        width: "100%",
                                        maxW: '90vw'
                                        }}
                                        colorScheme="blue"
                                    />
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            }
            { currentTicket && <TicketDrawer onClose={ () => showTicket(null) } ticketId={currentTicket} handleCloseTicket = { (ticketId) => { handleCloseTicket({status: "Closed", ticketId:ticketId}); setCurrentTicket(null)} } /> }
        </Box>
    )
}

const WebFilterOptions = (props) => {
    const { startDate, setStartDate, endDate, setEndDate, status, setStatus, getTickets, resetHandler } = props;
    return (
    <Flex justifyContent='space-between' alignItems='center' boxShadow='0px 3px 15px -10px' borderLeft='2px solid' borderRadius="3px" pl={4}>
        <Flex p={1} pl={0}>
            <Flex p={1}>
                <Box>
                    <Box fontWeight='bold'>Start Date</Box>
                    <Input variant='ghost' pl="0px" maxW='195px' type="date" value={startDate} onChange={ (e) => setStartDate(e.target.value) }></Input>
                </Box>
            </Flex>
        </Flex>
        
        <Flex p={2} pl={0}>
            
            <Box>
                <Box fontWeight='bold'>End Date</Box>
                <Input variant='ghost' pl="0px" maxW='195px' type="date" value={endDate} onChange = { e => setEndDate(e.target.value) }></Input>
            </Box>
        </Flex>
        <Flex p={2} pl={0}>
            
            <Box>
                <Box fontWeight='bold'>Status</Box>
                <Select variant='ghost' style={{  maxWidth:'195px', paddingLeft: '0px' }} value={status} onChange={ (e) => setStatus(e.target.value) }>
                    <option value=''>Select</option>
                    <option value='Pending'>Pending</option>
                    <option value='Open'>Open</option>
                    <option value='Closed'>Closed</option>
                    <option value='Duplicate'>Duplicate</option>
                </Select>
            </Box>
        </Flex>
        <Flex pr={2} py={4}>
            <Button boxShadow='0px 5px 15px -5px #38a169' size='sm' color='white' colorScheme='green' mr={2} onClick={ () => getTickets({}) }>Apply</Button>
            <Button size='sm' variant='ghost' onClick={ resetHandler } >Reset</Button>
        </Flex> 
    </Flex>)
}

const MobileFilterOptions = ({setShowFilterModal}) => {
    return (
        <Button colorScheme='orange' fontSize={[ 12, 15, 18 ]} variant='ghost' onClick={ () => setShowFilterModal(true) }><EditIcon mr={2}/>Filters</Button>
    )
}

const FilterModal = (props) => {
    const { startDate, setStartDate, onClose, endDate, setEndDate, status, setStatus, getTickets, resetHandler } = props;
    return (
        <Modal isOpen={true} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader></ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Box py={4} px={2}>
                    <Flex justifyContent='space-between'>
                        <Box fontSize={[ 15, 17, 20, 22 ]} fontWeight='bold'>Start Date</Box>
                        <Input variant='ghost' pl="0px" maxW='135px' type="date" value={startDate} onChange={ (e) => setStartDate(e.target.value) }></Input>
                    </Flex>
                    <Flex justifyContent='space-between'>
                        <Box fontSize={[ 15, 17, 20, 22 ]} fontWeight='bold'>End Date</Box>
                        <Input variant='ghost' pl="0px" maxW='135px' type="date" value={endDate} onChange = { e => setEndDate(e.target.value) }></Input>
                    </Flex>
                    <Flex justifyContent='space-between'>
                        <Box fontSize={[ 15, 17, 20, 22 ]} fontWeight='bold'>Status</Box>
                        <Box maxWidth='135px'>
                            <Select variant='ghost' style={{ paddingLeft: '0px' }} value={status} onChange={ (e) => setStatus(e.target.value) }>
                                <option value=''>Select</option>
                                <option value='Pending'>Pending</option>
                                <option value='Open'>Open</option>
                                <option value='Closed'>Closed</option>
                                <option value='Duplicate'>Duplicate</option>
                            </Select>
                        </Box>
                    </Flex>
                    <Flex justifyContent='flex-end'>
                        <Button size='sm' variant='ghost' colorScheme='orange' onClick={ resetHandler } >Reset</Button>
                    </Flex>
                </Box>
            </ModalBody>

            <ModalFooter>
                <Button variant='ghost' mr={3} onClick={onClose}>
                Close
                </Button>
                <Button size='sm' color='white' colorScheme='green' mr={2} onClick={ getTickets }>Apply</Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

const TicketMobileFormat = ({ allTicket , handleCloseTicket, onUserNameClick , currentUser, isUpdating, showTicket}) => {
    return (
        <Box>
            <Box>
                {map(allTicket, t => (
                    <Box cursor="pointer" onClick={() => showTicket(t._id)}>
                        <SingleTicketMobileView onUserNameClick = {onUserNameClick} currentUser={currentUser} isUpdating={isUpdating} ticket={t}/>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

const SingleTicketMobileView = ({ticket, onUserNameClick , currentUser, isUpdating }) => {
    const tickektPrimaryColor = _.find(statusList,l => l.value === ticket.status)?.color;
    console.log("tickektPrimaryColor",tickektPrimaryColor)
    return (
        <Box py={3} px={4} my={2} boxShadow='0px 2px 15px -14px' _hover={{ boxShadow:'md' }} borderRadius="3px" borderLeft='3px solid' borderColor={tickektPrimaryColor + ".400"}>
        <Text noOfLines={3} fontWeight='bold'>{ticket.message}</Text>
        <Flex mt={2} mb={1} alignItems='center' flexWrap='wrap'>
            <Button pl="0px" size="xs" variant='ghost' colorScheme={tickektPrimaryColor}>{ticket.status}</Button>
            {
                !currentUser &&
                <Box fontSize={[ 11, 11, 13 ]} onClick={(e) => {
                    e.stopPropagation()
                    onUserNameClick(ticket.userId)} } mr={2}>
                    <Link color="gray.700">{ticket.userId.name}</Link>
                </Box>
            }
            <Box color="gray.500" fontSize={[ 11, 11, 13 ]}>{moment(ticket.createdAt).format('DD-MM-YYYY hh:mm a')}</Box>
        </Flex>
    </Box>
    )
}

const TicketTableFormat = ({ allTicket , handleCloseTicket, onUserNameClick , currentUser, isUpdating, showTicket}) => {
     
    const closeTicket = (ticketId) => {
        handleCloseTicket({ status: "Closed", ticketId: ticketId })
    }
    return (
        <Box mt={3} boxShadow='0px 4px 20px -10px #4559d2'>
            <Box minH="100vh" borderRadius="3px" overflow="hidden">
                <Table>
                    <Thead bg="#1a202c" css={{ "& > th": { color:'white' } }}>
                        {
                            !currentUser &&
                            <Th>
                                Student
                            </Th>
                        }
                        <Th>
                            Subject
                        </Th>
                        <Th>
                            Category
                        </Th>
                        <Th w='370px'>
                            Message
                        </Th>
                        <Th minW='125px'>
                            Date
                        </Th>
                        <Th>
                        Status
                        </Th>
                        <Th>
                        Actions
                        </Th>
                    </Thead>
                    <Tbody fontSize='.9rem'>
                        {
                            map(allTicket, t => (
                                <Tr _hover={{ boxShadow:'md' }}>
                                    {
                                        !currentUser &&
                                        <Td onClick={() => onUserNameClick(t.userId) }>
                                           <Link color="blue.600">{t.userId.name}</Link></Td>
                                    }
                                    <Td>{ t.subject && t.subject}</Td>
                                    <Td>{t.category}</Td>
                                    <Td><Tooltip label={t.message} hasArrow><Text cursor='pointer' noOfLines={3}>{t.message}</Text></Tooltip></Td>
                                    <Td>{moment(t.createdAt).format('DD-MM-YYYY hh:mm a')}</Td>
                                    <Td><Button size='sm' variant='ghost' colorScheme={_.find(statusList,l => l.value === t.status)?.color}>{t.status}</Button></Td>
                                    <Td>
                                        <Flex flexDir='column' maxW='100px'>
                                            <Button size='xs' onClick={() => showTicket(t._id)} colorScheme='blue' boxShadow='0px 5px 15px -5px #3182ce' mb={2}>Show</Button>
                                            {t.status !== 'Closed' && <Button size="xs" variant='ghost' isDisabled={isUpdating} onClick={ () => closeTicket(t._id)}>Close ticket</Button>}
                                        </Flex>
                                    </Td>
                                </Tr>
                            ))
                        }
                    </Tbody>
                </Table>
            </Box>
        </Box>
    )
}

const TicketDrawer = ({onClose, ticketId, handleCloseTicket}) => {
    const { currentTicket, getTicketStatus } = useSelector( s => ({ currentTicket: s.ticket.currentTicket, getTicketStatus: s.ticket.getTicketStatus }) )
    const dispatch = useDispatch();
    useEffect( () => {
        if(ticketId)
            dispatch(getSingleTicketAction({ ticketId: ticketId }))
    },[ticketId])
    return (
      <Drawer
      isOpen={true}
      placement='right'
      onClose={ onClose }
      size="lg"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
            <Flex justifyContent='space-between'>
                <Box>
                    {currentTicket && <Box><Box>{currentTicket.userId.name}</Box><Flex color='gray.500' fontSize='.8rem'><i>{moment(currentTicket.createdAt).format("DD-MM-YYYY hh:mm a")}</i>
                    <Box borderBottom="1px solid" color="blue.400" fontSize='.7rem' ml={2}>{currentTicket.category}</Box></Flex></Box>}
                </Box>
                <Box>
                    <Button isDisabled={ currentTicket?.status === 'Closed' } onClick={ () => handleCloseTicket(ticketId) } variant='outline' size='sm' colorScheme='orange' mr={8} ml={3}>Close ticket</Button>
                </Box>
            </Flex>
        </DrawerHeader>

        <DrawerBody>
            {
                getTicketStatus === STATUS.SUCCESS ?
                    <Box>
                        <Box fontWeight='bold' >
                            <Box>subject : {currentTicket?.subject}</Box>
                        </Box>
                        <Box fontWeight='500'>{currentTicket?.message}</Box>
                    </Box>
                :
                <Center><Spinner size='md'></Spinner></Center> 
            }
            <Box mt={4}>
                <DiscussionComments
                    onBottomAtSmall = {true}
                    commentsLabel="Comments"
                    showAttachment={false}
                    disableReply
                    placeholder="Add a comment"
                    inputStyle="flushed"
                    itemId={ticketId}
                />
            </Box>
      </DrawerBody>
      </DrawerContent>
    </Drawer>
    )
}
