import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { STATUS } from "../../../App/Constants";
import { apis } from "../../../services/api/apis";
import _ from "lodash";
import { LoadingRef } from "../../../App/AppProvider";

export const getUserDoubtsAction = createAsyncThunk(
  "doubts/getdoubt",
  async (payload, thunkAPI) => {
    const response = await apis.getDoubtApi(payload);
    const { ok, problem, data } = response;

    if (ok) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(problem);
    }
  }
);

export const getTeacherDoubtsAction = createAsyncThunk(
  "doubts/getTeacherDoubt",
  async (payload, thunkAPI) => {
    LoadingRef.current.show();
    const response = await apis.getDoubtApi(payload);
    LoadingRef.current.hide();
    const { ok, problem, data } = response;

    if (ok) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(problem);
    }
  }
);

export const getAllTeachers = createAsyncThunk(
  "doubts/getAllTeachers",
  async (payload, thunkAPI) => {
    LoadingRef.current.show()
    const response = await apis.getAllTeachersApi(payload);
    LoadingRef.current.hide()
    const { ok, problem, data } = response;

    if (ok) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(problem);
    }
  }
);

export const getSingleDoubtAction = createAsyncThunk(
  "doubts/singledoubt",
  async (payload, thunkAPI) => {
    LoadingRef.current.show()
    const response = await apis.getSingleDoubtApi(payload);
    LoadingRef.current.hide()
    const { ok, problem, data } = response;

    if (ok) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(problem);
    }
  }
);

export const createNewDoubt = createAsyncThunk(
  "doubts/createNewDoubt",
  async (payload, thunkAPI) => {
    const response = await apis.createNewDoubt(payload);
    const { ok, problem, data } = response;
    if (ok) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(problem);
    }
  }
);

export const closeDoubtAction = createAsyncThunk(
  "doubts/close",
  async (payload, thunkAPI) => {
    const response = await apis.closeDoubtApi(payload);
    const { ok, problem, data } = response;
    if (ok) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(problem);
    }
  }
);

export const publicDoubtAction = createAsyncThunk(
  "doubts/public",
  async (payload, thunkAPI) => {
    const response = await apis.closeDoubtApi(payload);
    const { ok, problem, data } = response;
    if (ok) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(problem);
    }
  }
);

export const getTeacherAssingedAction = createAsyncThunk(
  "get-teacher-assigned",
  async (payload, thunkAPI) => {
    const response = await apis.getTeacherAssingedApi(payload);
    const { ok, problem, data } = response;
    if (ok) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(problem);
    }
  }
);

export const getDoubtsAction = createAsyncThunk(
  "get-doubts/get",
  async (payload, thunkAPI) => {
    LoadingRef.current.show();
    const response = await apis.getDoubtsApi(payload);
    LoadingRef.current.hide();
    const { ok, problem, data } = response;
    if (ok) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(problem);
    }
  }
);

export const readCommentAction = createAsyncThunk(
  "read-comment/post",
  async (payload, thunkAPI) => {
    LoadingRef.current.show();
    const response = await apis.readCommentApi(payload);
    LoadingRef.current.hide();
    const { ok, problem, data } = response;
    if (ok) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(problem);
    }
  }
);

export const getSingleCommonDoubtsAction = createAsyncThunk(
  "single-common-doubt/get",
  async (payload, thunkAPI) => {
    LoadingRef.current.show();
    const response = await apis.getSingleCommonDoubtsApi(payload);
    LoadingRef.current.hide();
    const { ok, problem, data } = response;
    if (ok) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(problem);
    }
  }
);

let initialState = {
  userAskedDoubts: {},
  teacherDoubts: {},
  allTeachers: [],
};

const doubtSlice = createSlice({
  name: "doubt",
  initialState,
  reducers: {
    resetAddDoubtStatus: (state) => {
      state.createNewDoubtStatus = STATUS.NOT_STARTED;
    },
    resetTeacherDoubts: (state) => {
      state.teacherDoubts = {};
    },
    resetUserDoubts: (state) => {
      state.userAskedDoubts = {};
    },

    resetTeacherAssignedData:state => {
      state.teacherAssignedData = []
    },

    resetReadAction:state => {
      state.readCommentStatus = STATUS.NOT_STARTED
    }
  },
  extraReducers: {
    [getSingleCommonDoubtsAction.pending]: (state) => {
      state.getSingleCommonDoubtsStatus = STATUS.FETCHING;
    },
    [getSingleCommonDoubtsAction.fulfilled]: (state, action) => {
      state.getSingleCommonDoubtsStatus = STATUS.SUCCESS;
      state.currentCommonDoubt = action.payload
    },
    [getSingleCommonDoubtsAction.rejected]: (state) => {
      state.getSingleCommonDoubtsStatus = STATUS.FAILED;
    },
    
    [readCommentAction.pending]: (state) => {
      state.readCommentStatus = STATUS.FETCHING;
    },
    [readCommentAction.fulfilled]: (state, action) => {
      state.readCommentStatus = STATUS.SUCCESS;

      let indx = _.findIndex(state.commonDoubts,d => d._id === action.payload?._id)
      if( indx !== -1 )
        state.commonDoubts[indx] = action.payload
    },
    [readCommentAction.rejected]: (state) => {
      state.readCommentStatus = STATUS.FAILED;
    },

    [getDoubtsAction.pending]: (state) => {
      state.getDoubtsStatus = STATUS.FETCHING;
    },
    [getDoubtsAction.fulfilled]: (state, action) => {
      state.getDoubtsStatus = STATUS.SUCCESS;
      state.commonDoubts = action.payload?.docs;
      // state.commonDoubtsContent = action.payload?.commonDoubts;
 
      // if(state.commonDoubts?.length){
      //   state.commonDoubts =  _.map(state.commonDoubts,dbt => ({...dbt, content:_.find(action.payload.commonDoubts,d => d.itemId === dbt.itemId?._id)}))
      // }
      state.commonDoubtsPageConfig = {
        limit: action.payload.limit,
        page: action.payload.page,
        total: action.payload.total,
        pages: action.payload.pages,
      };
    },
    [getDoubtsAction.rejected]: (state) => {
      state.getDoubtsStatus = STATUS.FAILED;
    },
    
    [getTeacherAssingedAction.pending]: (state) => {
      state.getTeacherAssingedStatus = STATUS.FETCHING;
    },
    [getTeacherAssingedAction.fulfilled]: (state, action) => {
      state.getTeacherAssingedStatus = STATUS.SUCCESS;
      state.teacherAssignedData = action.payload
    },
    [getTeacherAssingedAction.rejected]: (state) => {
      state.getTeacherAssingedStatus = STATUS.FAILED;
    },
    
    [getSingleDoubtAction.pending]: (state) => {
      state.getSingleDoubtStatus = STATUS.FETCHING;
    },
    [getSingleDoubtAction.fulfilled]: (state, action) => {
      state.getSingleDoubtStatus = STATUS.SUCCESS;
      state.currentDoubt = action.payload
    },
    [getSingleDoubtAction.rejected]: (state) => {
      state.getSingleDoubtStatus = STATUS.FAILED;
    },
    
    [publicDoubtAction.pending]: (state) => {
      state.publicDoubtStatus = STATUS.FETCHING;
    },
    [publicDoubtAction.fulfilled]: (state, action) => {
      let indx = _.findIndex(state.teacherDoubts,d => d._id === action.payload._id) 
      if(indx !== -1)
        state.teacherDoubts[indx] = action.payload

      if(state.currentDoubt)
        state.currentDoubt = action.payload

      state.publicDoubtStatus = STATUS.SUCCESS;
    },
    [publicDoubtAction.rejected]: (state) => {
      state.publicDoubtStatus = STATUS.FAILED;
    },
    
    [closeDoubtAction.pending]: (state) => {
      state.closeDoubtStatus = STATUS.FETCHING;
    },
    [closeDoubtAction.fulfilled]: (state, action) => {
      let indx = _.findIndex(state.teacherDoubts,d => d._id === action.payload._id) 
      if(indx !== -1)
        state.teacherDoubts[indx] = action.payload

      if(state.currentDoubt)
        state.currentDoubt = action.payload
      state.closeDoubtStatus = STATUS.SUCCESS;
    },
    [closeDoubtAction.rejected]: (state) => {
      state.closeDoubtStatus = STATUS.FAILED;
    },

    [getUserDoubtsAction.pending]: (state) => {
      if (!current(state.userAskedDoubts)?.docs)
        state.getUserDoubtStatus = STATUS.FETCHING;
      else state.getMoreUserDoubtStatus = STATUS.FETCHING;
    },

    [getUserDoubtsAction.fulfilled]: (state, action) => {
      if (!current(state.userAskedDoubts)?.docs) {
        state.userAskedDoubts = action.payload;
        state.getUserDoubtStatus = STATUS.SUCCESS;
      } else {
        state.userAskedDoubts.docs = _.union(
          current(state.userAskedDoubts.docs),
          action.payload?.docs
        );
        state.userAskedDoubts.page = action.payload?.page;
        state.getMoreUserDoubtStatus = STATUS.SUCCESS;
      }
    },

    [getUserDoubtsAction.rejected]: (state) => {
      if (!current(state.userAskedDoubts)?.docs)
        state.userAskedDoubts = STATUS.FAILED;
      else state.getMoreUserDoubtStatus = STATUS.FAILED;
    },

    [getTeacherDoubtsAction.pending]: (state) => {
      state.getTeacherDoubtsStatus = STATUS.FETCHING;
    },

    [getTeacherDoubtsAction.fulfilled]: (state, action) => {
      state.teacherDoubts = action.payload?.docs;
      state.pageConfig = {
        limit: action.payload.limit,
        page: action.payload.page,
        total: action.payload.total,
        pages: action.payload.pages,
      };
      state.getTeacherDoubtsStatus = STATUS.SUCCESS;
    },

    [getTeacherDoubtsAction.rejected]: (state) => {
      state.getTeacherDoubtsStatus = STATUS.FAILED;
    },

    [getAllTeachers.pending]: (state) => {
      state.getAllTeachersStatus = STATUS.FETCHING;
    },

    [getAllTeachers.fulfilled]: (state, action) => {
      state.getAllTeachersStatus = STATUS.SUCCESS;
      state.allTeachers = action.payload;
    },

    [getAllTeachers.rejected]: (state) => {
      state.getAllTeachersStatus = STATUS.FAILED;
    },

    [createNewDoubt.pending]: (state) => {
      state.createNewDoubtStatus = STATUS.FETCHING;
    },

    [createNewDoubt.fulfilled]: (state, action) => {
      state.createNewDoubtStatus = STATUS.SUCCESS;
      state.userAskedDoubts.docs = _.union(
        [action.payload],
        current(state.userAskedDoubts.docs)
      );
      state.userAskedDoubts.total += 1;
    },

    [createNewDoubt.rejected]: (state) => {
      state.createNewDoubtStatus = STATUS.FAILED;
    },
  },
});

// export const {resetModalStatus} = discussionSlice.actions

export const { resetAddDoubtStatus, resetTeacherDoubts, resetUserDoubts, resetTeacherAssignedData, resetReadAction } =
  doubtSlice.actions;
export const doubtReducer = doubtSlice.reducer;
