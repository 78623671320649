import {
  cocotestBaseUrl,
  liveBaseUrl,
  localBaseUrl,
  testBaseUrl,
} from "../BaseUrl";

export function getBaseUrl(host) {
  if (host === "localhost") {
    return testBaseUrl;
  } else if (host === "public.coco.coachinglog.in") {
    return testBaseUrl;
  } else if (host === "students.coco.coachinglog.in") {
    return cocotestBaseUrl;
  } else if (host === "mentor.coco.coachinglog.in" || host === "competitioncommunity.com" || host ==='mentor.competitioncommunity.com') {
    return liveBaseUrl;
  } else {
    return cocotestBaseUrl;
  }
}
