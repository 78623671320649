import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { STATUS } from "../../../App/Constants";
import { apis } from "../../../services/api/apis";
import _ from "lodash";
import { LoadingRef } from "../../../App/AppProvider";

export const getUserCallback = createAsyncThunk(
    "callbacks/getcallback",
    async (payload, thunkAPI) => {
        const response = await apis.teacherAppointmentApi(payload);
        const { ok, problem, data } = response;

        if (ok) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(problem);
        }
    }
);

export const updateCallbackAction = createAsyncThunk(
    "callbacks/update",
    async (payload, thunkAPI) => {
        const response = await apis.updateCallbackApi(payload);
        const { ok, problem, data } = response;

        if (ok) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(problem);
        }
    }
);

let initialState = {
    userAskedDoubts: {},
    teacherDoubts: {},
    allTeachers: [],
};

const callbackSlice = createSlice({
    name: "callback",
    initialState,
    reducers: {
        setcallback: (state, action) => {
            state.data = action.payload
        },
        setAppState: (s, action) => {
            if (action.payload === APP_STATE.PUBLIC) {
                s.loginStatus = STATUS.NOT_STARTED
            }
            s.appstate = action.payload
        },
    },
    extraReducers: {
        [getUserCallback.pending]: (state) => {
            state.getUserCallbackStatus = STATUS.FETCHING;
        },
        [getUserCallback.rejected]: (state) => {
            state.getUserCallbackStatus = STATUS.FAILED;
        },
        [getUserCallback.fulfilled]: (state, action) => {
            state.getUserCallbackStatus = STATUS.SUCCESS

            if(action.payload){
                state.currentCallback = action.payload.docs
                state.pageConfig = _.omit(action.payload, 'docs')
            } else{
                state.currentCallback = []
                state.pageConfig=null
            }
        },

        [updateCallbackAction.pending]: (state) => {
            state.updateCallbackStatus = STATUS.FETCHING;
        },
        [updateCallbackAction.rejected]: (state) => {
            state.updateCallbackStatus = STATUS.FAILED;
        },
        [updateCallbackAction.fulfilled]: (state, action) => {
            state.updateCallbackStatus = STATUS.SUCCESS

            const indx = _.findIndex(state.currentCallback,d => d._id === action.payload._id)

            if(indx !== -1){
                if(  action.payload['status'] && state.currentCallback[indx]['status'] !== action.payload['status']){
                    if(action.payload['status'] === 'OPEN'){
                        state.pageConfig.closeCount =  state.pageConfig.closeCount - 1;
                        state.pageConfig.openCount =  state.pageConfig.openCount + 1;
                    }else if(action.payload['status'] === 'CLOSE'){
                        state.pageConfig.closeCount =  state.pageConfig.closeCount + 1;
                        state.pageConfig.openCount =  state.pageConfig.openCount - 1;
                    }
                }
                state.currentCallback[indx] = {...action.payload, studentId:state.currentCallback[indx].studentId}
            }
        },
    }
})

export const { setcallback, setAppState } = callbackSlice.actions;
export const callbackReducer = callbackSlice.reducer;
