import { getBaseUrl } from "./utils/baseUrlHelper";

// export const BaseURL = "http://192.168.2.138:4000/"
export const localBaseUrl = "http://localhost:4000/";
export const testBaseUrl = "https://api.coco.coachinglog.in/";
export const cocotestBaseUrl = "https://live.coco.coachinglog.in/";
export const liveBaseUrl = "https://api.competitioncommunity.com/";

const isclient = typeof window !== "undefined";
if (isclient) {
  console.log("[loc]", window.location);
}
export const BaseURL = isclient
  ? getBaseUrl(window.location?.hostname)
  : cocotestBaseUrl
