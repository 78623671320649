import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { Flex, Box, Tag, Heading, HStack, VStack, Text, List, ListItem, Input, InputGroup, InputLeftElement, IconButton, Tooltip, useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, Textarea, ModalFooter, Select, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel } from '@chakra-ui/react';
import { Search2Icon, ChevronRightIcon, ArrowDownIcon, ArrowUpIcon, ChevronDownIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from "react-redux";
import { getAllTeachers } from '../../redux/reducers/doubt';
import { AiOutlineClear, AiOutlineUndo } from "react-icons/ai";
import { mobileView, webView } from '../../utils/Helper';
import { map } from 'lodash';
import moment from 'moment';
import { getUserCallback, updateCallbackAction } from '../../redux/reducers/callback';
import Pagination from '@choc-ui/paginator';
import { BsCardText } from "react-icons/bs";
import { STATUS } from '../../Constants';
import { ErrorChecker } from '../../Components/ErrorChecker';


export const CallBacks = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const history = useHistory()

    const allTeachers = useSelector(state => state.doubts.allTeachers);
    const callback = useSelector(s => s.callback.currentCallback)
    
    const {pageConfig, user, getUserCallbackStatus} = useSelector(state => ({
        pageConfig:state.callback.pageConfig,
        user:state.user.user,
        getUserCallbackStatus:state.callback.getUserCallbackStatus
    }))

    const { isOpen, onOpen, onToggle, onClose } = useDisclosure();
    const [searchData, setSearchData] = useState({})
    const [formKey, SetFormKey] = useState()
    const [currentTeacher, setCurrentTeacher] = useState()
    const [active, setActive] = useState()
    const isAdmin = user?.staff?.staffRole === "ADMIN"

    useEffect(() => {
        if(allTeachers?.length && params.staffId){
          const staff = _.find(allTeachers,t => t.user?._id === params.staffId)
          setCurrentTeacher(staff)
        }
      }, [allTeachers, params])

    const checkRole = useMemo(() => {
        return (
            user?.staff?.staffRole === "ADMIN" ||
            user?.staff?.staffRole === "HEAD_TEACHER"
            // user?.staff?.staffRole === "TEACHER" 
        );
    }, [user?.staff?.staffRole]);

    const getCallback = useCallback(() => {
        if (params.staffId)
            dispatch(
                getUserCallback({ staffId: params.staffId, ...searchData, page:params.page })
            );
        else if(user)
                dispatch(
                    getUserCallback({ staffId: user._id, ...searchData, page:params.page })
                )
        else if(isAdmin)
            dispatch(
                getUserCallback({ page: params.page, staffId: user?._id, ...searchData,})
            );
    }, [params.staffId, params.page, dispatch, user, isAdmin])

    const getTeacher = useCallback(() => {
        if (checkRole) dispatch(getAllTeachers());
    }, [dispatch, checkRole])

    useEffect(() => {
        getTeacher();
    }, [getTeacher])

    useEffect(() => {
        getCallback()
    }, [getCallback])

    const handleChangeSearch = (e) => {
        setSearchData(d => ({...d, name:e.target.value}))
    }
    const handleSearch = (e) => {
        e.preventDefault()
    }
    const resetSearchData = () => {
        setSearchData({})
        dispatch(getUserCallback({ staffId: params.staffId || user._id}))
        SetFormKey(d => d + 1)
    }
    const openCallback = (a) => {
        setActive(a);
        onOpen()
    }

    const handlePageChange = (page) => {
        if(params.staffId)
            history.push(`/callbacks/${page}/${params.staffId}`)
        else
            history.push(`/callbacks/${page}`)

        // dispatch(getUserCallback({ staffId: params.staffId,student, page}))
    }

    const searchStudent = (e) => {
        e.preventDefault()
        dispatch(getUserCallback({ staffId: params.staffId || user._id, ...searchData}))

    }

    const handleStatusChange = (e) => {
        setSearchData(d => ({...d, status:e.target.value}))
    }

    return (
        <Box p={[2, 4, 8]} px={[1, 5, 10]} minH="100vh">
            <Heading fontSize="lg">Teacher Appointments</Heading>
            <br/>
            <Flex align={"stretch"} flexWrap={['wrap', 'wrap', 'wrap', 'nowrap']}>
                {checkRole && (
                    <Box flex={3} pr={5} {...webView}>
                        <MemorizeTeachersList />
                    </Box>
                )}
                <Box flex={10} >
                    <HStack>
                        <VStack flex={1} >
                            <Text color={'brand.blue'} fontWeight={'bold'} fontSize={[22, 24, 27, 30]}>
                                {pageConfig?.closeCount || 0}
                            </Text>
                            <Text style={{textAlign:'center'}} fontSize={[12, 15, 17, 20]}>Closed Appointments</Text>
                        </VStack>
                        <VStack flex={1} >
                            <Text color={'brand.blue'} fontWeight={'bold'} fontSize={[22, 24, 27, 30]}>
                                {pageConfig?.openCount || 0}
                            </Text>
                            <Text style={{textAlign:'center'}} fontSize={[12, 15, 17, 20]}>Open Appointments</Text>
                        </VStack>
                        {checkRole ?
                            <VStack flex={1}>
                                <Text color={'brand.blue'} fontWeight={'bold'} fontSize={[22, 24, 27, 30]}>
                                    {allTeachers?.length || 0}
                                </Text>
                                <Text fontSize={[12, 15, 17, 20]}>Teachers</Text>
                            </VStack>
                            :
                            null
                        }
                    </HStack>
                    <br />
                    <Box>
                        <ErrorChecker status={getUserCallbackStatus}>
                            {getUserCallbackStatus === STATUS.SUCCESS ?
                                <>
                                    {checkRole && 
                                        <>
                                            <br/>
                                            <MobileTeacherList resetSearch={resetSearchData} />
                                        <br/>
                                        </>
                                    }
                                    <form key={formKey} onSubmit={searchStudent}>
                                        <Box p={3}>
                                            <Text fontWeight={'600'} fontSize={'lg'}>{currentTeacher?.user?.name}</Text>
                                        </Box>
                                        <Flex width={'100%'} wrap='wrap' mb={5} align='center'>
                                            <Box w={['100%', '100%', '100%', '20%']} pr={6} p={2}>
                                                <Select onChange={handleStatusChange} value={searchData.status} placeItems={'Select appointment status'}>
                                                    <option value={''}>Select Status</option>
                                                    <option value={'OPEN'}>Opened</option>
                                                    <option value={'CLOSE'}>Closed</option>
                                                </Select>
                                            </Box>
                                            <HStack w={['100%', '100%', '100%', '80%']} align={'center'} p={2}>
                                                <Box flex={1}>
                                                    <Input minWidth={"200px"} value={searchData.name} size="lg" name='text' onChange={handleChangeSearch} placeholder="Search Student" />
                                                </Box>
                                                <Tooltip placement='top' label='Search'>
                                                    <Button type="submit" leftIcon={<Search2Icon />} >Search</Button>
                                                </Tooltip>
                                                <Tooltip placement='top' label='Reset'>
                                                    <IconButton onClick={resetSearchData} icon={<AiOutlineUndo fontSize={20} />} />
                                                </Tooltip>
                                            </HStack>
                                        </Flex>
                                    </form>

                                    {callback?.length ? map(callback, (d, i) => {
                                        return (
                                            <CallBackItem onPress={openCallback} d={d} i={i + 1 + pageConfig?.page * pageConfig?.limit - pageConfig.limit} />
                                        );
                                    }) : null}
                                    <br/>
                                    {pageConfig ?
                                        <HStack align="center">
                                        {/*<ReactPaginate previousLabel={"Prev"} nextLabel={"Next"} breakLabel={"..."} breakClassName={"break-me"}
                                            pageCount={discussionData?.forumList?.pages} marginPagesDisplayed={2} pageRangeDisplayed={5}
                                            onPageChange={changePage} containerClassName={"pagination"} subContainerClassName={"pages pagination"}
                                            activeClassName={"active"} previousClassName={'prevButton'} nextClassName={'nexButton'}
                                        />*/}
                                        <Pagination
                                            current={pageConfig?.page}
                                            total={pageConfig?.total}
                                            pageSize={pageConfig?.limit}
                                            onChange={handlePageChange}
                                            paginationProps={{
                                            display: "flex",
                                            justifyContent: "center",
                                            width: "100%",
                                            }}
                                            colorScheme="blue"
                                        />
                                        </HStack> : null
                                    }
                                </>
                                :
                                'Something went wrong'   
                            }
                        </ErrorChecker>
                    </Box>
                    {/* {isOpen ? (
                        <CallbackDetails currentCallback={active} isOpen={isOpen} onClose={onClose} />
                    ) : null} */}
                </Box>
            </Flex>
        </Box>
    );
};

const MobileTeacherList = ({resetSearch}) => {
    const params = useParams()
    const { teachers } = useSelector((state) => ({
      teachers: state.doubts.allTeachers,
    }));
  
    const [currentTeacher, setTeacher] = useState()
    const [openIndex, changeOpenIndex] = useState([0])
  
    useEffect(() => {
        changeOpenIndex([])
    }, [params.staffId])
  
    useEffect(() => {
      if(params.staffId && teachers){
        let staff = _.find(teachers,t => t.user?._id === params.staffId)
        setTeacher(staff)
      }
  
    }, [params, teachers])
  
    const handleOpen = () => {
      changeOpenIndex(d => d.length ? [] : [0])
    }
  
    return (
      <>
      <Accordion index={openIndex} allowMultiple {...mobileView}>
        <AccordionItem>
          <AccordionButton onClick={handleOpen}>
            <Box flex="1" textAlign="left">
              <HStack justify={'space-between'}>
                <Text color={'brand.blue'}>{currentTeacher?.user.name}</Text>
                <Text>Teachers ({teachers?.length}) &nbsp;</Text>
              </HStack>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <MemorizeTeachersList resetSearch={resetSearch} hideHeading />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Flex justifyContent='flex-end' display={ [ 'flex', 'flex', 'flex', 'none' ] } my={2}>
        {/* {
          currentTeacher &&
            <Box>
              {
                currentTeacher?.doubtCountResolved && <Tag colorScheme="red" mr={2}>Closed ({currentTeacher.doubtCountResolved})</Tag>
              }
              <Tag colorScheme="blue">Total ({currentTeacher?.doubtCount || 0})</Tag>
            </Box>
        } */}
      </Flex>
    </>
    );
  };

const WebTeachersList = ({ resetSearch }) => {
    const history = useHistory();
    const params = useParams();
    const dispatch = useDispatch();
    const [allTeachers, setAllTeachers] = useState([])
    // const [sortOn, setSortOn] = useState('callback');
    // const [sortType, changeSortType] = useState('asc')
    const { teachers } = useSelector(state => ({
        teachers: state.doubts.allTeachers
    }));
    useEffect(() => {
        if (teachers?.length)
            setAllTeachers(teachers)
    }, [teachers])

    // useEffect(() => {
    //     handleSort();
    // }, [sortOn, sortType])

    const handleTeacher = (teacher) => {
        // resetSearch()
        history.push(`/callbacks/1/${teacher.user?._id}`)
    }
    
    // useEffect(() => {
    //     dispatch(getAllTeachers())
    // }, [dispatch])
    
    const handleChange = (e) => {
        let searchText = e.target.value
        let list = _.filter(teachers, t => _.includes(_.lowerCase(t?.user?.name), _.lowerCase(searchText)))
        setAllTeachers(list)
    }
    // const handleSort = () => {
    //     if (sortOn === 'callback')
    //         setAllTeachers(d => _.orderBy(d, (callback) => !callback.callbackCount ? 0 : callback.callbackCount, sortType))
    //     else
    //         setAllTeachers(d => _.orderBy(d, (callback) => !callback.callbackCountResolved || !callback.callbackCount ? 0 : (doubt.doubtCountResolved / doubt.doubtCount), sortType))
    // }
    return (
        <List h="100%">
            <ListItem {...webView} background={"gray.100"} borderRadius={4} px={4} py={2}>
                <HStack>
                    <Text>Teachers</Text>
                    <Text>({allTeachers?.length || 0})</Text>
                </HStack>
            </ListItem>
            <ListItem my={3}>
                <HStack>
                    <Box flex={1}>
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<Search2Icon color='gray.300' />}
                            />
                            <Input onChange={handleChange} placeholder="Search Teacher" />
                        </InputGroup>
                    </Box>
                </HStack>
            </ListItem>
            {/* <ListItem my={3}>
                <Flex>
                    <Button onClick={() => changeSortType(d => d === 'asc' ? 'desc' : 'asc')} size="sm" variant="outline" colorScheme="blue" width={'100%'} mr={3}
                        rightIcon={sortType === 'asc' ? <ArrowDownIcon /> : <ArrowUpIcon />}
                    >
                        <Box>Sort </Box>
                    </Button>
                    <Menu>
                        <MenuButton as={Button} size='sm' px={4} colorScheme='blue' rightIcon={<ChevronDownIcon />}>
                            {sortOn === 'callback' ? "Count" : "Top"}
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={() => setSortOn('callback')}>Callback count</MenuItem>
                            <MenuItem onClick={() => setSortOn('top comment')}>Top</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </ListItem> */}
            <List maxH='100vh' overflowY='scroll' overflowX='hidden' pos='sticky' top='0px' css={{ '&::-webkit-scrollbar': { width: '6px' }, '&::-webkit-scrollbar-thumb': { background: '#cdcdcd', borderRadius: '5px' } }}>
                {allTeachers?.length ?
                    allTeachers.map((teacher) => {
                        const active = teacher.user?._id === params.staffId;
                        return teacher.user && (
                            <ListItem
                                borderRadius={2}
                                my={1}
                                cursor={"pointer"}
                                _hover={{ background: "blue.200", color: "white" }}
                                p={1}
                                px={2}
                                key={teacher._id}
                                transition="all .3s"
                                onClick={() => handleTeacher(teacher)}
                                background={active && "brand.blue"}
                                color={active ? "white" : "brand.secondary"}
                            >
                                <HStack justify={"space-between"} alignItems='flex-start'>
                                    <Box pt={1}>
                                        <Text>{teacher.user?.name}</Text>
                                        <Text fontSize="xs">{teacher.staffDesc == null ?
                                            teacher.staffRole : teacher.staffDesc
                                        }</Text>
                                    </Box>
                                </HStack>
                            </ListItem>
                        );
                    })
                    : null
                }
            </List>
        </List>
    )
}

const MemorizeTeachersList = React.memo(WebTeachersList)

const CallBackItem = ({ onPress, d, i }) => {
    const dispatch = useDispatch()
    const updateCallbackStatus = useSelector(state => state.callback.updateCallbackStatus)

    const [remarkModal, openRemarkModal] = useState()
    const callbackId = useRef()

    useEffect(() => {
        if(updateCallbackStatus === STATUS.SUCCESS){
            openRemarkModal(false)
            callbackId.current = null
        }
    }, [updateCallbackStatus])
    
    const callbackStatus = d.status !== "OPEN" ?
        { label: "Closed", color: "red" }
        : { label: "Open", color: "green" };

    const _open = () => {
        onPress(d);
    }

    const handleAddRemark = () => {
        openRemarkModal(d => !d)
    }

    const handleClose = (id) => {
        callbackId.current = id
        dispatch(updateCallbackAction({id:d._id, status:d.status === 'OPEN' ? 'CLOSE' : 'OPEN'}))
    }
    return (
        <Flex lineHeight={1.8} wrap='wrap' onClick={_open} p={3} _hover={{ bg: "gray.100", boxShadow: "sm" }} border="1px solid #dddddd" borderRadius={"md"} my={4} >
            <Box mr={5} >
                <Text fontSize="lg" color="gray.700">
                    {i}
                </Text>
            </Box>
            <Box flex={1}>
                <HStack justifyContent={'space-between'}>
                    <HStack spacing={{ base: 0, lg: 8 }} flexWrap={{ base:'wrap', lg: 'nowrap' }}>
                        <Text fontSize="sm" fontWeight={'bold'} color="gray.600">
                            {d.fullName}
                        </Text>
                        <Text color="gray.500" fontSize="sm">
                            <i>scheduled at <b>{moment(d.createdAt).format("DD MMM YYYY hh:mm a")}</b></i>
                            <i> for </i>
                            <i><b>{moment(d.startTime).format("DD MMM YYYY hh:mm a")}</b></i>
                        </Text>
                    </HStack>
                </HStack>
                <HStack wrap={'wrap'} spacing={8}>
                    <Box>
                        <Text fontSize="sm" color="gray.600">
                            Contact: {d.mobile}
                        </Text>
                    </Box>
                    <Box>
                        <Tag style={{margin:'6px 0'}} colorScheme={callbackStatus.color}>{callbackStatus.label}</Tag>
                    </Box>
                </HStack>
                <Box
                  fontSize="sm"
                  fontFamily="Lato"
                  as="pre"
                  sx={{
                    "white-space": "-moz-pre-wrap",
                    "white-space": "-o-pre-wrap",
                    "word-wrap": "break-word",
                    "white-space": "pre-wrap",
                  }}
                >
                  {d.message}
                </Box>
                {d.teacherRemark &&
                    <Box>
                        <Text color="gray.500" fontSize="sm">
                            <b>Teacher's Remark:</b> {d.teacherRemark || '-'}
                        </Text>
                    </Box>
                }
                {d.studentRemark &&
                    <Box>
                        <Text color="gray.500" fontSize="sm">
                            <b>Student's Remark:</b> {d.studentRemark || '-'}
                        </Text>
                    </Box>
                }
            </Box>
            <Box w={['100%', '100%', '100%', '20%']} pl={7} pt={[3, 3, 3, 0]}>
                <HStack justify={['start', 'start', 'start', 'end']} align={'start'}>
                    <Button variant='outline' background={'white'} size='sm' leftIcon={<BsCardText fontSize={16}/>}
                        onClick={handleAddRemark}
                    >
                        Remark
                    </Button>
                    {d.status === 'OPEN' ?
                        <Tooltip placement='top' label='Close Appointment'>
                            <Button variant='outline' colorScheme='red' background={'white'} size='sm' 
                                onClick={() => handleClose(d._id)} isLoading={callbackId.current === d._id && updateCallbackStatus === STATUS.FETCHING}
                            >
                                Close
                            </Button>
                        </Tooltip>
                        :
                        <Tooltip placement='top' label='Open Appointment'>
                            <Button variant='outline' colorScheme='green' background={'white'} size='sm' 
                                onClick={() => handleClose(d._id)} isLoading={callbackId.current === d._id && updateCallbackStatus === STATUS.FETCHING}
                            >
                                Open
                            </Button>
                        </Tooltip>
                    }
                </HStack> 
            </Box>
            {remarkModal ? <RemarkModal visible={remarkModal} closeModal={handleAddRemark} callback={d} /> : null}
        </Flex>
    )
}

const RemarkModal = ({closeModal, visible, callback }) => {
    const dispatch = useDispatch()

    const updateCallbackStatus = useSelector(state => state.callback.updateCallbackStatus)

    const [remark, changeRemark] = useState()

    useEffect(() => {
        if(callback.teacherRemark){
            changeRemark(callback.teacherRemark)
        }
    }, [callback])

    const handleSubmit = () => {
        dispatch(updateCallbackAction({id:callback._id, teacherRemark:remark}))
    }

    return(
        <Modal isOpen={visible} size='2xl' onClose={closeModal}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Add Remark</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <FormControl>
                        <FormLabel>Remark</FormLabel>
                        <Textarea
                            placeholder={'Remark'}
                            rows={4}
                            onChange={e => changeRemark(e.target.value)}
                            value={remark}
                        />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button isLoading={updateCallbackStatus === STATUS.FETCHING} onClick={handleSubmit} colorScheme='blue'>Add</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

// const CallbackDetails = ({ isOpen, onClose, currentCallback }) => {
//     console.log("current", currentCallback)
//     return (
//         <Drawer isOpen={isOpen} size={["xl"]} placement="right" onClose={onClose}>
//             <DrawerOverlay />
//             <DrawerContent>
//                 <DrawerCloseButton />
//                 <DrawerHeader>
//                     <Box alignItems='center'>
//                         <Box color="gray.800">{currentCallback?.user?.name}</Box>
//                         <Box fontSize={13} color="gray.500"><i>{moment(currentCallback.createdAt).format("DD MM YYYY, hh mm a")}</i></Box>
//                     </Box>
//                 </DrawerHeader>
//                 <DrawerBody style={{ maxHeight: "100vh" }}>
//                     {/* <DoubtComponent currentDoubt={currentDoubt} /> */}
//                 </DrawerBody>
//             </DrawerContent>
//         </Drawer>
//     )
// }